import React, {
  useEffect,
  useState,
  ChangeEvent,
  useCallback,
  MutableRefObject,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import { AlertBox } from './styles';

interface FormProps {
  label: string;
  value: string | number | undefined;
  isRequired?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  isUndefined?: boolean;
  isNull?: boolean;
  placeholder?: string;
  onChange(data: number, data1: boolean): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  refs?: MutableRefObject<any>;
  placeholderToLeft?: boolean;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const InputNumber: React.FC<FormProps> = forwardRef((props, refs) => {
  const {
    label,
    value,
    isRequired = false,
    onChange,
    min = 0,
    max = 99999,
    maxLength = 9,
    isUndefined = false,
    isNull = null,
    placeholder = `Digite um valor para ${label}`,
    setInvalid = false,
    iniInicializado = false,
    isDisabled = false,
    autoFocus = false,
    placeholderToLeft = false,
  } = props;
  // armazena se a validação foi iniciada
  const [inicializado, setInicializado] = useState(false);
  // armazena se o input passou na validação
  const [isInvalid, setIsInvalid] = useState(false);

  const inputRef = useRef<any>();

  const validation = useCallback(
    (_newValue: string) => {
      let newValue = Number.parseInt(_newValue, 10);
      if (_newValue !== newValue.toString()) {
        _newValue = _newValue.replace(/^[0]+/g, '');
        newValue = Number.parseInt(_newValue, 10);
      }
      if (inicializado && isRequired) {
        if (isUndefined && newValue === undefined) {
          setIsInvalid(true);
          onChange(newValue, true);
          return;
        }
        if (isNull && newValue === null) {
          setIsInvalid(true);
          onChange(newValue, true);
          return;
        }
      }
      if (inicializado) {
        if (newValue < min) {
          setIsInvalid(true);
          onChange(newValue, true);
          return;
        }
        if (newValue > max) {
          onChange(newValue, true);
          return;
        }
      }
      onChange(newValue, false);
      setIsInvalid(false);
      setInicializado(true);
    },
    [inicializado, isNull, isRequired, isUndefined, max, min, onChange],
  );

  // Efetuam a validação caso o input não seja alterado e o botão submit seja clicado
  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  const addAlert = useCallback((): string => {
    if (inicializado && isInvalid) {
      return 'alert-class';
    }
    return '';
  }, [inicializado, isInvalid]);

  useImperativeHandle(props.refs, () => ({
    focus: () => (inputRef.current ? inputRef.current.focus() : null),
  }));

  return (
    <AlertBox>
      <div className={addAlert()}>
        <Field label={label} name={label}>
          {({ fieldProps }) => (
            <>
              <Textfield
                {...fieldProps}
                type="number"
                className={
                  placeholderToLeft ? 'placeholderLeft' : 'placeholderRight'
                }
                min={min}
                max={max}
                step="1"
                ref={inputRef}
                autoFocus={autoFocus}
                value={value}
                placeholder={placeholder}
                isDisabled={isDisabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  validation(e.target.value);
                }}
              />
            </>
          )}
        </Field>
      </div>
    </AlertBox>
  );
});

export default InputNumber;
