const getDigitsFromValue = (value = '') =>
  value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const padDigits = (digits: string | any[]) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = '0'.repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number: any) => number.replace(/^0+([0-9]+)/, '$1');

const addDecimalToNumber = (number: any, separator: any) => {
  const centsStartingPosition = number.length - 2;
  let dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
  const cents = number.substring(centsStartingPosition);
  // Milhar
  if (dollars.length === 4) {
    const milhar = dollars.substring(0, 1);
    dollars = dollars.substring(1, dollars.length);
    return `${milhar}.${dollars}${separator}${cents}`;
  }
  // Dezena de milhar
  if (dollars.length === 5) {
    const milhar = dollars.substring(0, 2);
    dollars = dollars.substring(2, dollars.length);
    return `${milhar}.${dollars}${separator}${cents}`;
  }
  // Centena de milhar
  if (dollars.length === 6) {
    const milhar = dollars.substring(0, 3);
    dollars = dollars.substring(3, dollars.length);
    return `${milhar}.${dollars}${separator}${cents}`;
  }
  // milhão
  if (dollars.length === 7) {
    const milhao = dollars.substring(0, 1);
    const milhar = dollars.substring(1, 4);
    dollars = dollars.substring(4, dollars.length);
    return `${milhao}.${milhar}.${dollars}${separator}${cents}`;
  }
  // dezena de milhão
  if (dollars.length === 8) {
    const milhao = dollars.substring(0, 2);
    const milhar = dollars.substring(2, 5);
    dollars = dollars.substring(5, dollars.length);
    return `${milhao}.${milhar}.${dollars}${separator}${cents}`;
  }
  // centena de milhão
  if (dollars.length === 9) {
    const milhao = dollars.substring(0, 3);
    const milhar = dollars.substring(3, 6);
    dollars = dollars.substring(6, dollars.length);
    return `${milhao}.${milhar}.${dollars}${separator}${cents}`;
  }
  return dollars + separator + cents;
};

export const toCurrency = (value: string, separator = '.'): string => {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits);
  return addDecimalToNumber(digitsWithPadding, separator);
};

// const teste = Intl.NumberFormat('pt-BR', {
//   maximumSignificantDigits: 7,
// }).format(Number.parseFloat(_newValue));
