import React, { useEffect, useState, ChangeEvent, useCallback } from 'react';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';

import { AlertBox } from './styles';
import { moedaFormatada } from './formatMask';

interface FormProps {
  label: string;
  invalidLabel?: string;
  value: string | number | undefined;
  casas: '.01' | '.001' | '.0001';
  isRequired?: boolean;
  min?: number;
  max?: number;
  isUndefined?: boolean;
  isNull?: boolean;
  isEmpty?: boolean;
  placeholder?: string;
  onChange(data: number | string, data1: boolean): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const InputPercent: React.FC<FormProps> = (props) => {
  const {
    label,
    value,
    casas = '.01',
    isRequired = false,
    onChange,
    min = 0,
    max = 99999,
    isUndefined = false,
    isNull = null,
    isEmpty = null,
    placeholder = `Digite um valor para ${label}`,
    setInvalid = false,
    iniInicializado = false,
    isDisabled = false,
    autoFocus = false,
  } = props;
  // armazena se a validação foi iniciada
  const [inicializado, setInicializado] = useState(false);
  // armazena se o input passou na validação
  const [isInvalid, setIsInvalid] = useState(false);

  const validation = useCallback(
    (_newValue: string) => {
      // if (_newValue === '') return onChange(_newValue, false);

      // let valueAsCurrency: any = '';
      // let newValue = 0;
      // valueAsCurrency = moedaFormatada(_newValue, ',');
      // let valueToFloat = '';
      // if (valueAsCurrency.length < 7) {
      //   valueToFloat = valueAsCurrency.replace(',', '.');
      // } else {
      //   valueToFloat = valueAsCurrency.replace('.', '');
      //   valueToFloat = valueToFloat.replace(',', '.');
      // }
      // newValue = Number.parseFloat(valueToFloat);

      // // const setValue = Number.parseFloat(_newValue);

      // if (newValue > max) {
      //   // setIsInvalid(true);
      //   // onChange(_newValue, true);
      //   return;
      // }
      if (_newValue === '') return onChange(_newValue, false);

      // const regexNumber = new RegExp(/[0-9,.]+$/g);
      // if (!regexNumber.test(_newValue)) {
      //   return onChange(_newValue, false);
      // }

      const serializedValue = moedaFormatada(_newValue);
      let valueToFloat = '';
      if (serializedValue.length < 7) {
        valueToFloat = serializedValue.replace(',', '.');
      } else {
        valueToFloat = serializedValue.replace('.', '');
        valueToFloat = valueToFloat.replace(',', '.');
      }

      if (parseFloat(valueToFloat) > max) {
        setIsInvalid(true);
        // onChange(valueToFloat, true);
        return;
      }

      onChange(serializedValue, false);

      // if (inicializado && isRequired) {
      //   if (isUndefined && _newValue === undefined) {
      //     // setIsInvalid(true);
      //     onChange(_newValue, true);
      //     return;
      //   }
      //   if (isNull && _newValue === null) {
      //     // setIsInvalid(true);
      //     // onChange(_newValue, true);
      //     return;
      //   }
      //   if (isEmpty && _newValue === '') {
      //     // setIsInvalid(true);
      //     onChange(_newValue, true);
      //     return;
      //   }
      //   if (isEmpty && _newValue.trim() === '') {
      //     // setIsInvalid(true);
      //     onChange(_newValue, true);
      //     return;
      //   }

      //   if (setValue > max) {
      //     // setIsInvalid(true);
      //     onChange(_newValue, true);
      //     return;
      //   }
      // }
      // if (inicializado) {
      //   if (setValue < min) {
      //     // setIsInvalid(true);
      //     onChange(_newValue, true);
      //     return;
      //   }
      //   if (setValue > max) {
      //     setIsInvalid(true);
      //     onChange(_newValue, true);
      //     return;
      //   }
      // }
      // onChange(_newValue, false);
      // setIsInvalid(false);
      // setInicializado(true);
    },
    [max, onChange],
  );

  const setDecimal = useCallback(
    (_newValue: string) => {
      if (casas === '.01') {
        _newValue = Number.parseFloat(_newValue).toFixed(2);
      }
      if (casas === '.001') {
        _newValue = Number.parseFloat(_newValue).toFixed(3);
      }
      if (casas === '.0001') {
        _newValue = Number.parseFloat(_newValue).toFixed(4);
      }
      onChange(_newValue, false);
    },
    [casas, onChange],
  );

  // Efetuam a validação caso o input não seja alterado e o botão submit seja clicado
  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);

  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  const addAlert = useCallback((): string => {
    if (inicializado && isInvalid) {
      return 'alert-class';
    }
    return '';
  }, [inicializado, isInvalid]);

  return (
    <AlertBox>
      <div className={addAlert()}>
        <Field label={label} name={label || 'inputPercent2'}>
          {({ fieldProps }) => (
            <>
              <Textfield
                {...fieldProps}
                type="text"
                min={min}
                max={max}
                // step={casas}
                autoFocus={autoFocus}
                value={value}
                isDisabled={isDisabled}
                placeholder={placeholder}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  validation(e.target.value);
                }}
                style={{ textAlign: 'right', paddingRight: '40px' }}
              />
              <Button
                type="button"
                className="inputButton"
                isDisabled={isDisabled}
                style={{
                  float: 'right',
                  marginTop: '-40px',
                  height: '40px',
                  alignItems: 'center',
                  cursor: 'inherit',
                }}
                tabIndex={-1}
              >
                %
              </Button>
            </>
          )}
        </Field>
      </div>
    </AlertBox>
  );
};

export default InputPercent;
