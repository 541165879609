import React from 'react';
import { nanoid } from 'nanoid';
import { Radio } from '@atlaskit/radio';

import { Container, RadioColor } from './styles';

interface Options {
  label: string;
  value: number | string;
}

interface FormProps {
  label: string;
  options: Options[];
  invalidLabel?: string;
  value: string | number | undefined;
  isRequired?: boolean;
  onChange(data: string | number | undefined, data1: boolean): any;
  isDisabled?: boolean;
  isDisabledColor?: boolean;
  name?: string;
  control?: any;
}

const InputRadioButton: React.FC<FormProps> = (props) => {
  const {
    label,
    value,
    options,
    onChange,
    isDisabled = false,
    name,
    isDisabledColor = false,
  } = props;

  const handleTipoPessoa = (val: string | number | undefined) => {
    onChange(val, false);
  };

  return (
    <Container style={{ marginTop: '30px' }}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          border: '2px solid #DFE1E6',
          padding: '6px 10px',
          borderRadius: '3px',
          minHeight: '40px',
        }}
      >
        <p className="label">{label}</p>
        {options.map((option) => {
          const randId = nanoid();
          return (
            <RadioColor
              style={{ backgroundColor: isDisabledColor ? '#E5E5E5' : '' }}
              isActived={value === option.value}
              key={nanoid()}
            >
              <Radio
                value={option.value.toString()}
                label={option.label}
                name={name || `radio-${randId}`}
                testId={`radio-${randId}`}
                id={`radio-${randId}`}
                isChecked={
                  isDisabledColor === false &&
                  value?.toString() === option.value.toString()
                }
                isDisabled={isDisabled}
                onChange={(e) => {
                  e.preventDefault();
                  handleTipoPessoa(option.value);
                }}
                style={{
                  color: 'red !important',
                }}
              />
            </RadioColor>
          );
        })}
      </div>
    </Container>
  );
};

export default InputRadioButton;
