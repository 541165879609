import styled from 'styled-components';

interface IAlertBox {
  inLine: boolean;
  fontSize: string;
}

export const AlertBox = styled.div<IAlertBox>`
  position: relative;
  height: 100%;

  .dateTime {
    label {
      font-size: 0.8571428571428571em;
      font-style: inherit;
      line-height: 1.3333333333333333;
      color: #6b778c;
      font-weight: 600;
      margin-top: 16px;
      display: inline-block;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      margin-bottom: 4px;
      margin-top: 0;
    }
    input {
      display: block;
      width: 100%;
      /* height: calc(1.5em + 0.75rem + 2px); */
      height: 40px;
      padding: 0.375rem 0.75rem;
      /* padding: 6px 10px; */
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      /* border: 2px solid #dfe1e6; */
      border-radius: 0.25rem;
      /* border-radius: 3px; */
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:focus {
        outline: none !important;
        box-shadow: none !important;
        border: 2px solid #4c9aff;
      }
    }
    .icon {
      float: right;
      margin-top: -40px;
      height: 40px;
      align-items: center;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-left: transparent;
      border-radius: 0rem 0.25rem 0.25rem 0rem;
      svg {
        margin-top: -3px;
      }
    }
  }
  .alert-class {
    > * {
      border-radius: 4px;
      position: relative;
      transition: 0.2s;
    }
    label {
      color: #cf1919 !important;
      transition: 0.2s;
    }
    .react-select__control {
      border-bottom: 2px solid #cf1919;
    }
    .react-select__control--is-focused {
      border-top: 2px solid;
      border-left: 2px solid;
      border-right: 2px solid;
      border-color: #cf1919;
      border-bottom: 2px solid #cf1919;
    }
    .sc-iqAclL {
      color: #cf1919 !important;
    }
    .lflqij {
      color: #cf1919 !important;
    }
    input {
      display: block;
      width: 100%;
      /* height: calc(1.5em + 0.75rem + 2px); */
      height: 40px;
      padding: 0.375rem 0.75rem;
      /* padding: 6px 10px; */
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-bottom: 2px solid #cf1919;
      /* border: 2px solid #dfe1e6; */
      border-radius: 0.25rem;
      /* border-radius: 3px; */
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: 0.1s;
      &:focus {
        outline: none !important;
        box-shadow: none !important;
        border: 2px solid #cf1919;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .dateTime {
      input {
        flex: 1;
      }
      ${(props) =>
        props.inLine === true
          ? `
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: absolute;
          bottom: 7px;
          width: 100% !important;
          .label--text {
            width: auto;
            margin-right: 10px;
            margin-top: 4px;
            font-size: ${props.fontSize};
            font-style: inherit;
            line-height: 1.3333333333333333;
            color: #6b778c;
            font-weight: 600;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          }
          `
          : ''};
    }
  }

  .css-1qsoeic[data-checked] {
    background-color: #8850bf !important;
    color: #ffffff;
  }
  .css-1jjtf24[data-checked] {
    background-color: #8850bf !important;
  }
  .daterangepicker .ltr .show-calendar .opensright {
    background-color: red !important;
  }

  .alterados--content .daterangepicker td.active {
    background-color: red !important;
  }
`;

export const Label = styled.div`
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: #6b778c;
  font-weight: 600;
  margin-top: 16px;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 4px;
  margin-top: 0;
`;
