import React, { useCallback, useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import Button from '@atlaskit/button';
// import { AiTwotoneCalendar } from 'react-icons/ai';
import moment from 'moment';
import { AlertBox } from './styles';
import '../../../styles/daterangepicker.css';

interface FormProps {
  dataIni: string;
  dataFim: string;
  isDisabled?: boolean;
  labelText: string;
  inLine?: boolean;
  fontSize?: string;
  isRequired?: boolean;
  setInvalid?: boolean;
  format?: 'DD/MM/YYYY HH:mm A' | 'DD/MM/YYYY';
  iniInicializado?: boolean;
  onChange(data: string, data1: string, data2: boolean): any;
}
/**
 * @param labelText Label para o toggle button
 * @returns Componente date picker
 */
const InputDateRange: React.FC<FormProps> = (props) => {
  const {
    dataIni = moment().subtract(3, 'days'),
    dataFim = moment(),
    labelText,
    inLine = false,
    fontSize = '0.8571428571428571em',
    isRequired = false,
    setInvalid = false,
    isDisabled = false,
    iniInicializado = false,
    format = 'DD/MM/YYYY HH:mm A',
    onChange,
  } = props;
  const [isInvalid, setIsInvalid] = useState(false);
  const [inicializado, setInicializado] = useState(false);
  const [inWidth, setInWidth] = useState(window.innerWidth);

  const validation = useCallback(
    (ini: string, fim: string) => {
      if (isRequired) {
        if (
          ini === '' ||
          ini === undefined ||
          fim === '' ||
          fim === undefined
        ) {
          setIsInvalid(true);
          onChange(ini, fim, true);
          return;
        }
      }
      onChange(ini, fim, false);
      setIsInvalid(false);
      setInicializado(true);
    },
    [isRequired, onChange],
  );

  const handleResize = () => {
    setInWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);
  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);
  const addAlert = useCallback((): string => {
    if (inicializado && isInvalid) {
      return 'alert-class';
    }
    return '';
  }, [inicializado, isInvalid]);

  return (
    <AlertBox
      style={{ marginTop: '7.4px' }}
      inLine={inLine}
      fontSize={fontSize}
    >
      <div className={`dateTime ${addAlert()}`}>
        {(inLine === false || inWidth < 993) && (
          <label htmlFor="birthdaytime">{labelText}</label>
        )}
        {inLine === true && inWidth > 992 && (
          <label className="label--text" htmlFor="birthdaytime">
            {labelText}
          </label>
        )}
        <DateRangePicker
          initialSettings={{
            timePicker: true,
            timePicker24Hour: true,
            timePickerIncrement: 15,
            maxYear: parseInt(moment().format('YYYY'), 15),
            startDate: dataIni,
            endDate: dataFim,
            locale: {
              format: `${format}`,
              applyLabel: 'Confirmar',
              cancelLabel: 'Cancelar',
              fromLabel: 'De',
              toLabel: 'Até',
              daysOfWeek: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
              monthNames: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
              ],
            },
          }}
          onCallback={(start, end) => {
            validation(start, end);
          }}
        >
          <input
            type="text"
            className="form-control col-4"
            disabled={isDisabled}
            style={{
              cursor: isDisabled ? 'not-allowed' : 'text',
              backgroundColor: isDisabled ? '#f0f0f0' : '#fff',
            }}
          />
        </DateRangePicker>
        {/* <Button type="button" className="icon">
          <AiTwotoneCalendar fontSize="16px" />
        </Button> */}
      </div>
    </AlertBox>
  );
};

export default InputDateRange;
