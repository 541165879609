import styled from 'styled-components';

interface IContainer {
  isActived: boolean;
}

export const Container = styled.div`
  .label {
    margin-top: -26px;
    margin-left: -13px;
    position: absolute;
    font-size: 0.8571428571428571em;
    line-height: 1.3;
    color: #6b778c;
    font-weight: 600;
    font-style: inherit;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  .css-14w6ssg-Radio:checked {
    --radio-background-color: ${({ theme: { colors } }) =>
      colors.nav}!important;
    --radio-border-color: ${({ theme: { colors } }) => colors.nav}!important;
    cursor: pointer;
  }
  .css-zq8lr3-Radio {
    color: #6b778c;
    padding: 2px 4px;
    cursor: pointer;
  }
`;

export const RadioColor = styled.div<IContainer>`
  background-color: ${(props) => (props.isActived ? '#57069e' : '#8850bf')};
  border-radius: 5px;
  height: 30px;
  min-width: 70px;
  display: flex;
  padding: 0px 5px;
  cursor: pointer;
  label{
    cursor: pointer;
  }
  > * {
    margin: auto;
    input{
      cursor: pointer;
    }
    input:focus {
      border-color: transparent !important;
      box-shadow: transparent !important;
    }
    input:checked {
      background-color: #8850bf; //57069e
      border-color: transparent !important;
    }
  }
  span {
    color: #fff !important;
    font: 14px 'Jost',sans-serif!important
    -webkit-font-smoothing: antialiased !important;
  }
  .css-zq8lr3-Radio {
    color: ${(props) => (props.isActived ? '#8850BF' : '#6b778c')};
  }
  & + :not(:first-child) {
    margin-left: 4px;
  }
`;
