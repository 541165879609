import moment, { Moment } from 'moment';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { format as fnsFormat } from 'date-fns';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { AlertBox } from './styles';

import '../../../styles/daterangepicker.css';

interface FormProps {
  value: string;
  isDisabled?: boolean;
  labelText: string;
  inLine?: boolean;
  fontSize?: string;
  isRequired?: boolean;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  format?: 'DD/MM/YYYY HH:mm A' | 'DD/MM/YYYY';
  timePicker?: boolean;
  timePicker24Hour?: boolean;
  onChange(data: string, data1: boolean): any;
  refs?: any;
  minDate?: Moment;
  autoUpdateInput?: boolean;
}
/**
 * @param labelText Label para o toggle button
 * @returns Componente date picker
 */
const InputDateTime: React.FC<FormProps> = forwardRef((props) => {
  const {
    value = moment(),
    labelText = '',
    inLine = false,
    fontSize = '0.8571428571428571em',
    isRequired = false,
    setInvalid = false,
    isDisabled = false,
    iniInicializado = false,
    format = 'DD/MM/YYYY HH:mm A',
    timePicker = true,
    timePicker24Hour = true,
    onChange,
    minDate,
    autoUpdateInput = false,
    refs,
  } = props;
  const myRef = useRef<any>();
  const [isInvalid, setIsInvalid] = useState(false);
  const [inicializado, setInicializado] = useState(false);
  const [inWidth, setInWidth] = useState(window.innerWidth);

  const validation = useCallback(
    (data: any) => {
      if (isRequired) {
        if (data === '' || data === undefined) {
          setIsInvalid(true);
          onChange(data, true);
          return;
        }
      }
      onChange(data, false);
      setIsInvalid(false);
      setInicializado(true);
    },
    [isRequired, onChange],
  );

  const handleApply = (event: any, picker: any) => {
    picker.element.val(`${picker.startDate.format(format)}`);
    onChange(picker.startDate.format(format), false);
  };

  const handleResize = () => {
    setInWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);
  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  const changeStartDate = (date: string) => {
    const newDate = fnsFormat(new Date(date), 'yyyy-MM-dd');
    myRef.current.setStartDate(moment(newDate));
  };
  const changeDate = (date: string) => {
    const newDate = fnsFormat(new Date(date), 'yyyy-MM-dd');
    myRef.current.setStartDate(moment(newDate));
    myRef.current.setEndDate(moment(newDate));
    myRef.current.$picker.val(moment(newDate).format('DD/MM/YYYY'));
  };
  useImperativeHandle(refs, () => ({
    changeStartDate,
    changeDate,
  }));

  const addAlert = useCallback((): string => {
    if (inicializado && isInvalid) {
      return 'alert-class';
    }
    return '';
  }, [inicializado, isInvalid]);

  return (
    <AlertBox
      style={{ marginTop: '7.4px' }}
      inLine={inLine}
      fontSize={fontSize}
    >
      <div className={`dateTime ${addAlert()}`}>
        {(inLine === false || inWidth < 993) && (
          <label htmlFor="birthdaytime">{labelText}</label>
        )}
        {inLine === true && inWidth > 992 && (
          <label className="label--text" htmlFor="birthdaytime">
            {labelText}
          </label>
        )}
        <DateRangePicker
          ref={myRef}
          initialSettings={{
            timePicker,
            timePicker24Hour,
            singleDatePicker: true,
            showDropdowns: true,
            startDate: value,
            minDate,
            minYear: 1901,
            maxYear: parseInt(moment().format('YYYY'), 15),
            autoApply: true,
            autoUpdateInput,
            locale: {
              format: `${format}`,
              applyLabel: 'Confirmar',
              cancelLabel: 'Cancelar',
              daysOfWeek: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
              monthNames: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
              ],
            },
          }}
          onApply={handleApply}
          onCallback={(start) => {
            validation(start);
            // para o postgres fica yyy-MM-dd
            // const years = moment().diff(start, 'years');
            // alert(`You are ${years} years old!`);
          }}
        >
          <input
            type="text"
            className="form-control col-4"
            id={labelText.trim()}
            name={labelText.trim()}
            disabled={isDisabled}
            style={{
              cursor: isDisabled ? 'not-allowed' : 'text',
              backgroundColor: isDisabled ? '#f0f0f0' : '#fff',
            }}
          />
        </DateRangePicker>
      </div>
    </AlertBox>
  );
});

export default InputDateTime;
