import React, { useEffect, useState, ChangeEvent, useCallback } from 'react';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import { nanoid } from 'nanoid';
import { toCurrency } from './formatMask';

import { AlertBox } from './styles';
import { indexOf } from 'lodash';

type HTMLInputProps = Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'>;
interface FormProps extends HTMLInputProps {
  label: string;
  value: any;
  isRequired?: boolean;
  min?: number;
  max?: number;
  isUndefined?: boolean;
  isNull?: boolean;
  maxLength?: number;
  isEmpty?: boolean;
  placeholder?: string;
  onChange(data: string, data1: boolean): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  withIcon?: boolean;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */

const InputMoney: React.FC<FormProps> = (props) => {
  const {
    label,
    value,
    isRequired = false,
    onChange,
    min = 0,
    max = 9999999,
    isUndefined = false,
    isNull = null,
    isEmpty = null,
    placeholder = `Digite um valor para ${label}`,
    setInvalid = false,
    iniInicializado = false,
    isDisabled = false,
    autoFocus = false,
    withIcon = true,
    ...rest
  } = props;
  // armazena se a validação foi iniciada
  const [inicializado, setInicializado] = useState(false);
  // armazena se o input passou na validação
  const [isInvalid, setIsInvalid] = useState(false);

  // eslint-disable-next-line no-shadow
  interface AddOperadorProps {
    numberMoney: string;
    operador?: string;
  }
  function addOperador({ numberMoney, operador = '' }: AddOperadorProps) {
    const newNumberMoney = numberMoney.replace('-', '');
    return operador + newNumberMoney;
  }

  const validation = useCallback(
    (_newValue: string) => {
      let operador = '';
      if (_newValue.indexOf('-') !== -1 && _newValue !== '-0,0') {
        operador = '-';
      }
      if (_newValue === '') return onChange(_newValue, false);
      let valueAsCurrency: any = '';
      let newValue = 0;
      valueAsCurrency = toCurrency(_newValue.replace('-', ''), ',');
      let valueToFloat = '';
      if (valueAsCurrency.length < 7) {
        valueToFloat = valueAsCurrency.replace('-', '');
        valueToFloat = valueAsCurrency.replace(',', '.');
      } else {
        valueToFloat = valueAsCurrency.replace('-', '');
        valueToFloat = valueAsCurrency.replace('.', '');
        valueToFloat = valueToFloat.replace(',', '.');
      }
      newValue = Number.parseFloat(operador + valueToFloat);
      if (inicializado && isRequired) {
        if (isUndefined && _newValue === undefined) {
          setIsInvalid(true);
          onChange(
            addOperador({ numberMoney: valueAsCurrency, operador }),
            true,
          );
          return;
        }
        if (isNull && _newValue === null) {
          setIsInvalid(true);
          onChange(
            addOperador({ numberMoney: valueAsCurrency, operador }),
            true,
          );
          return;
        }
        if (isEmpty && _newValue === '') {
          setIsInvalid(true);
          onChange(
            addOperador({ numberMoney: valueAsCurrency, operador }),
            true,
          );
          return;
        }
        if (isEmpty && _newValue.trim() === '') {
          setIsInvalid(true);
          onChange(
            addOperador({ numberMoney: valueAsCurrency, operador }),
            true,
          );
          return;
        }
      }
      if (inicializado) {
        if (newValue < min) {
          // onChange(min.toString(), false);
          return;
        }
        if (newValue > max) {
          // console.log('entrou aqui max', newValue);
          // onChange(max.toString(), false);
          return;
        }
      }
      onChange(addOperador({ numberMoney: valueAsCurrency, operador }), false);
      setIsInvalid(false);
      setInicializado(true);
    },
    [
      inicializado,
      isEmpty,
      isNull,
      isRequired,
      isUndefined,
      label,
      max,
      min,
      onChange,
    ],
  );

  // Efetuam a validação caso o input não seja alterado e o botão submit seja clicado
  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  // Adicionar
  const inicialValue = () => {
    if (value !== null && value !== undefined) {
      const val_min = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value);
      let valueAsCurrency: any = '';
      valueAsCurrency = toCurrency(val_min, ',');
      return valueAsCurrency;
    }
  };

  const addAlert = useCallback((): string => {
    if (inicializado && isInvalid) {
      if (iniInicializado) {
        return 'alert-class ';
      }
    }
    return '';
  }, [inicializado, isInvalid]);

  return (
    <AlertBox>
      <div className={addAlert()}>
        <Field label={label} name={`${label}${nanoid()}`}>
          {({ fieldProps }) => (
            <>
              <Textfield
                {...fieldProps}
                type="text"
                min={min}
                max={max}
                step="any"
                autoFocus={autoFocus}
                // Deixar assim
                // value={inicializado ? value : inicialValue()}
                value={value}
                placeholder={placeholder}
                isDisabled={isDisabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  validation(e.target.value);
                }}
                style={{
                  textAlign: 'right',
                  paddingRight: withIcon ? '45px' : '15px',
                }}
                {...rest}
              />
              {withIcon && (
                <Button
                  type="button"
                  className="inputButton"
                  style={{
                    float: 'right',
                    marginTop: '-40px',
                    height: '40px',
                    alignItems: 'center',
                    cursor: 'default',
                  }}
                  tabIndex={-1}
                >
                  R$
                </Button>
              )}
            </>
          )}
        </Field>
      </div>
    </AlertBox>
  );
};

export default InputMoney;
